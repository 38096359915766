import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Foswig from 'foswig'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const chain = new Foswig(
    3,
    data.wordsJson.spanish.nouns
      .concat(data.wordsJson.spanish.adjectives)
      .concat(data.wordsJson.spanish.verbs)
  )

  const constraints = {
    minLength: 4,
    maxLength: 10,
    allowDuplicates: false,
  }

  const [word, setWord] = useState(chain.generate(constraints))

  useEffect(() => {
    setWord(chain.generate(constraints))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de nombres de fantasía aleatorios"
        titleSeo="Generador de nombres de fantasía aleatorios"
        description="Herramienta para generar nombres de fantasía al azar para personajes y relatos, fantasías, mitos, leyendas."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Nombres', url: '/nombres/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de nombres de fantasía aleatorios
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => setWord(chain.generate(constraints))}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de nombres de fantas&iacute;a aleatorios</Heading1>
        <Paragraph>
          Imaginar distintos nombres ficticios puede aburrir o cansar
          r&aacute;pidamente, pero con este generador de nombres de
          fantas&iacute;a aleatorios podr&aacute;s generar decenas en
          cuesti&oacute;n de segundos desde la comodidad de la simple interfaz
          de usuario que se presenta m&aacute;s arriba.
        </Paragraph>
        <Paragraph>
          Ya sea que quieras nombrar a tus personajes en un relato, est&eacute;s
          escribiendo alg&uacute;n libro o historia en un contexto
          fant&aacute;stico, o simplemente te dispongas a jugar una partida de
          rol de mesa y necesites construir tu mundo, tener una herramienta para
          generar nombres de fantas&iacute;a al azar puede resultar muy
          &uacute;til y provechoso.
        </Paragraph>
        <Paragraph>
          La herramienta es tan simple de utilizar como el objetivo que busca
          cumplir: puedes dar un toque (en pantalla t&aacute;ctil, o un clic en
          ordenador) para generar un nombre completamente al azar, y repetir el
          mismo proceso cuantas veces quieras generando siempre un nombre nuevo
          y diferente a los anteriores.
        </Paragraph>
        <Paragraph>
          Existen algunas opciones de configuraci&oacute;n, pero su uso es
          opcional, para facilitar la experiencia de usuario el generador de
          nombres fant&aacute;sticos dispone de una configuraci&oacute;n por
          defecto que deber&iacute;a satisfacer las b&uacute;squedas m&aacute;s
          frecuentes, y en caso de no hacerlo, siempre se puede ajustar con las
          opciones disponibles.
        </Paragraph>
        <Heading2>
          &iquest;Qu&eacute; tipos de nombres de fantas&iacute;a se pueden
          generar? Reglas para su construcci&oacute;n
        </Heading2>
        <Paragraph>
          No existe como tal una definici&oacute;n &uacute;nica y universal
          acerca de qu&eacute; exactamente constituye un nombre de
          fantas&iacute;a, cu&aacute;l debe ser sus formas, que propiedades debe
          poseer o qu&eacute; reglas es necesario respetar. Dada esta
          situaci&oacute;n, uno puede generar nombres de fantas&iacute;a al azar
          con una t&eacute;cnica propia o empleando algunas de las muchas
          disponibles en internet; adem&aacute;s de intentar acercarse a
          alg&uacute;n mundo en particular (de alg&uacute;n libro,
          pel&iacute;cula, videojuego, etc.) o idioma, para tener cierta
          familiaridad con las personas que conocen dicho mundo.
        </Paragraph>
        <Paragraph>
          Este generador de nombres imaginarios al azar intenta ajustarse a lo
          que la gente conoce m&aacute;s popularmente como seres
          fant&aacute;sticos y mitol&oacute;gicos, con diferentes algoritmos que
          intentan simples pero efectivos al momento de generar nombres para
          criaturas y personajes de un contexto espec&iacute;fico, por ejemplo,
          si uno est&aacute; jugando una partida de rol de mesa utilizando el
          mundo de Harry Potter, hay ciertos rasgos propios de los nombres de
          dicha saga que la gente identificar&aacute; como &uacute;nicos o
          particulares, que diferenciar&aacute;n a los nombres a utilizar en una
          partida de GTA 5 Online ambientado en un futuro ciberpunk o los que
          podremos encontrarnos en un relato acerca del medievo con ogros y
          elfos.
        </Paragraph>
        <Heading3>
          Nombres en idiomas imaginarios: Dothraki, Quenya, Klingon,
          Na&rsquo;vi, etc.
        </Heading3>
        <Paragraph>
          M&aacute;s all&aacute; de contextos espec&iacute;ficos para el
          generador de nombres imaginarios aleatorios, tambi&eacute;n es posible
          intentar obtener nombres de fantas&iacute;a basados en alg&uacute;n
          lenguaje ficticio como lo son el <strong>Dothraki</strong>, creado por{' '}
          <em>George R. R. Martin</em> en sus libros de &ldquo;
          <strong>Canci&oacute;n de hielo y fuego</strong>&rdquo; y por
          popularizado por la serie de televisi&oacute;n &ldquo;
          <strong>Juego de tronos</strong>&rdquo;; el <strong>Quenya</strong>,
          conocido tambi&eacute;n como <em>Alto &Eacute;lfico</em> y creado por{' '}
          <em>J. R. R. Tolkien</em> para &ldquo;
          <strong>El se&ntilde;or de los anillos</strong>&rdquo;; el{' '}
          <strong>Klingon</strong>, propio de la franquicia &ldquo;
          <strong>Star Trek</strong>&rdquo;, que si bien ya tiene sus
          a&ntilde;os sigue siendo uno de los m&aacute;s populares y demandados,
          todo un cl&aacute;sico a estas alturas; el{' '}
          <strong>Na&rsquo;Vi</strong>, que surgi&oacute; a partir de la
          pel&iacute;cula &ldquo;
          <strong>Avatar</strong>&rdquo; a pedido de su director{' '}
          <em>James Cameron</em>; y as&iacute; podr&iacute;amos seguir
          mencionando muchos otros idiomas m&aacute;s, incluso, por qu&eacute;
          no, &iexcl;aventurarnos a incluir el Esperanto en esta lista, ja!
        </Paragraph>
        <Paragraph>
          La idea se entiende llegados a este punto, en l&iacute;neas generales
          el generador de nombres ficticios al azar intentar&aacute; ajustar lo
          mejor posible a las reglas de dichos idiomas imaginarios, pero de
          tener cualquier consulta, duda o queja si&eacute;ntete libre de dejar
          tu comentario en la secci&oacute;n de abajo o contactarnos para
          hacernos llegar tu opini&oacute;n, estamos constantemente trabajando
          en mejorar el generador de nombres imaginarios aleatorios para poder
          satisfacer a las distintas necesidades espec&iacute;ficas de los
          usuarios.
        </Paragraph>
        <Heading3>Criaturas mitol&oacute;gicas y leyendas populares</Heading3>
        <Paragraph>
          A lo largo de nuestra historia, las distintas civilizaciones humanas
          han tenido tiempo de sobra para crear ricos y variados mundos
          fant&aacute;sticos llenos de nombres de fantas&iacute;a populares hoy
          en d&iacute;a, por lo que una opci&oacute;n que no puede faltar en el
          generador de nombres mitol&oacute;gicos aleatorios es la de incluir
          nombres parecidos o basados en la mitolog&iacute;a griega, romana,
          egipcia, azteca, entre muchas otras.
        </Paragraph>
        <Paragraph>
          Por ejemplo, entre los dioses romanos algunos de los m&aacute;s
          populares son J&uacute;piter, Juno, Minerva, Vulcano, Diana, Febo,
          Venus, Ceres, Neptuno, Marte, Mercurio, Vesta. Que a su vez
          est&aacute;n basados en la mitolog&iacute;a griega, donde nos podemos
          encontrar a Zeus (primero en la lista e infaltable), Hera, Artemisa,
          Apolo, Atenea, Hefesto, Afrodita, Hades, Poseid&oacute;n, Ares,
          Hermes, Dionisio, etc.
        </Paragraph>
        <Paragraph>
          Y as&iacute; hay muchas otras criaturas pertenecientes a una gran
          variedad de culturas que de una u otra forma han dejado registro de
          sus historias y f&aacute;bulas, y sobrevivido hasta el d&iacute;a de
          hoy. El generador de nombres mitol&oacute;gicos al azar no intenta
          simplemente tomar un nombre de la lista disponible y seleccionarlo,
          sino m&aacute;s bien tomar todos los nombres de un contexto en
          particular y generar nombres de fantas&iacute;a con
          caracter&iacute;sticas similares a los mismos, pero que sean
          originales.
        </Paragraph>
        <Heading2>
          &Uacute;salo para lo que gustes, no hay l&iacute;mites
        </Heading2>
        <Paragraph>
          Un generador de nombres de fantas&iacute;a aleatorios es realmente
          simple y cumple una funci&oacute;n concreta, pero eso no implica que
          no tenga muchos y muy variados usos. Ya hemos mencionado algunos de
          ellos, pero es que realmente hay muchas situaciones en los
          cu&aacute;les resultar pr&aacute;ctico y c&oacute;modo poder generar
          nombres de fantas&iacute;a al azar y hasta nos puede sacar de
          alg&uacute;n apuro.
        </Paragraph>
        <Paragraph>
          Uno de los usos m&aacute;s evidentes es para asignar nombres a
          personas al intentar construir un mundo imaginario, ya sea para un
          relato breve, todo un libro, un simple comentario de internet, alguna
          producci&oacute;n audiovisual, una partida de rol de mesa, la
          creaci&oacute;n de un clan en un videojuego, entre muchas otras.
        </Paragraph>
        <Paragraph>
          Si tienes preferencias respecto a alg&uacute;n estilo, idioma o mundo
          fant&aacute;stico ya existente puedes ajustar el generador para que
          produzca nombres que sean acordes a lo que buscas, o escribirnos con
          sugerencias respecto a algo que puede estar faltando o que
          quiz&aacute; no funcione como esperabas. Despu&eacute;s de todo, lo
          que son nombres veros&iacute;miles para mundos y culturas ya
          existentes puede ser altamente subjetivo y siempre est&aacute; la
          posibilidad de realizar ajustes al algoritmo de generaci&oacute;n de
          nombres de fantas&iacute;a al azar, para ello tenemos la
          secci&oacute;n de comentarios y la p&aacute;gina de contacto.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        nouns
        adjectives
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
